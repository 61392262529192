import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Button from "components/CustomButtons/Button.js";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';

import pricingStyle from "assets/jss/material-kit-pro-react/views/pricingSections/pricingStyle.js";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
const useStyles = makeStyles(pricingStyle);

const iframe = '<iframe id="saFormsIframe" src="https://my.serviceautopilot.com/v3/ViewForm?id=f2f0172d-62e4-4306-a520-8ff231bfe984&websiteHost=1" width="100%" height="1000" scrolling="auto" frameborder="0" style="overflow-y:visible;"></iframe>'; 

function Iframe(props) {
  return (<div dangerouslySetInnerHTML={ {__html:  props.iframe?props.iframe:""}} />);
}
export default function SectionPricing() {
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const classes = useStyles();
  return (
    <div className={classes.pricingSection}>
      <GridContainer>
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar className={classes.appBar} color="default" > 
          <Toolbar>
            <IconButton edge="start" color="default" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
                <Iframe iframe={iframe} />,
      </Dialog>
        <GridItem
          md={6}
          sm={6}
          className={classNames(
            classes.mrAuto,
            classes.mlAuto,
            classes.textCenter
          )}
        >
          <h2 className={classNames(classes.title, classes.textCenter)}>
            Our Packages
          </h2>
          <h4 className={classNames(classes.description, classes.textCenter)}>
          As well as offering services separately we have packages that you can choose from that best fit your lawn care needs. Choosing a package can help save you money throughout the season. Bundle your services today!
          </h4>
      
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem md={4} sm={4}>
          <Card plain pricing>
            <CardBody pricing>
            <h2
                className={classNames(classes.cardCategory, classes.textInfo)}
              >Basic Package</h2>
              
              <ul>
                <li>
                  <b>Weekly or Bi-Weekly</b> Lawn Mowing
                </li>
                <li>
                  <b>Online</b> Invocing
                </li>
                <li>
                  <b>Client</b> Portal
                </li>
               
              </ul>
           
              <Button href="" color="success" round onClick={handleClickOpen} >
                Learn More
              </Button>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem md={4} sm={4}>
          <Card raised pricing color="success">
            <CardBody pricing>
              <h2 className={classes.cardCategory}>Standard Package</h2>
            
              <ul>
                <li>
                  <b>Spring</b> Clean Up
                </li>
                <li>
                  <b>Weekly or Bi-Weekly  </b> Lawn Mowing
                </li>
                <li>
                  <b>Fall</b> Clean up
                </li>
              </ul>
              <Button color="white" round onClick={handleClickOpen} >
              
              Learn More
              </Button>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem md={4} sm={4}>
          <Card plain pricing>
            <CardBody pricing>
              <h2
                className={classNames(classes.cardCategory, classes.textInfo)}
              >
                Premium Package: 
              </h2>
              <h1 className={classes.cardTitle}>
                
              </h1>
              <ul>
                <li>
                  <b>Dethatching </b> and Spring Cleanup
                </li>
                <li>
                  <b>Weekly or Bi-Weekly</b> Lawn Mowing
                </li>
                <li>
                  <b>Aeration</b> and Fall Cleanup.
                </li>
               
              </ul>
              <Button color="success" round onClick={handleClickOpen} >
                Learn More
              </Button>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
