import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import officeStyle from "assets/jss/material-kit-pro-react/views/aboutUsSections/officeStyle.js";



const useStyles = makeStyles(officeStyle);

export default function SectionOffice() {
  const classes = useStyles();
  return (
    <div className={classes.office}>
      <GridContainer className={classes.textCenter}>
        <GridItem
          md={8}
          sm={8}
          className={classNames(classes.mrAuto, classes.mlAuto)}
        >
          
          <h4 className={classes.description}  style={{paddingTop: "50px"}}>
          We offer a wide arrangement of services throughout the year. All of our services are provided with the highest quality, whether it be weekly Lawn Maintenance service or snow plowing expect the same great customer service.</h4>
          <h2 className={classes.title}>Pricing and Estimates </h2>
          <h4 className={classes.description}>
          We offer very competitive pricing. We keep our prices low while still providing you with the most professional service. We always offer free estimates so contact us today for your free estimate!
          </h4>
          <h2 className={classes.title}>Our Services </h2>
          <h4 className={classes.description}>
          As well as offering services separately we have packages that you can choose from that best fit your lawn care needs. Choosing a package can help save you money throughout the season. Bundle your services today!</h4>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem md={4} sm={4}>
          <img
            className={classNames(
              classes.imgRaised,
              classes.imgFluid,
              classes.rounded
            )}
            src="https://storage.googleapis.com/mowforyourmoney-images/landinglawnmowing.jpeg"
            alt="Lawn"
          />
        </GridItem>
        <GridItem md={4} sm={4}>
          <img
            className={classNames(
              classes.imgRaised,
              classes.imgFluid,
              classes.rounded
            )}
            src="https://storage.googleapis.com/mowforyourmoney-images/104309_7891474d8466445fa4b73f7b4ada30ce~mv2_d_3264_2448_s_4_2.jpg"
            alt="Lawn"
          />
        </GridItem>
        <GridItem md={4} sm={4}>
          <img
            className={classNames(
              classes.imgRaised,
              classes.imgFluid,
              classes.rounded
            )}
            src='https://storage.googleapis.com/mowforyourmoney-images/aboutUS.jpeg'
            alt="Lawn"
          />
        </GridItem>
        <GridItem md={6} sm={6}>
          <img
            className={classNames(
              classes.imgRaised,
              classes.imgFluid,
              classes.rounded
            )}
            src='https://storage.googleapis.com/mowforyourmoney-images/IMG_0971.jpeg'
            alt="Lawn"
          />
        </GridItem>
        <GridItem md={6} sm={6}>
          <img
            className={classNames(
              classes.imgRaised,
              classes.imgFluid,
              classes.rounded
            )}
            src='https://storage.googleapis.com/mowforyourmoney-images/houselanding.JPG'
            alt="office5"
          />
        </GridItem>
      </GridContainer>
    </div>
  );
}
