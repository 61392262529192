import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import blogsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/blogsStyle.js";

const useStyles = makeStyles(blogsStyle);

export default function SectionBlogs({ ...rest }) {
  const classes = useStyles();
  return (
    <div className="cd-section" {...rest}>
      <div className={classes.blog}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem
              xs={12}
              sm={10}
              md={10}
              className={
                classes.mlAuto + " " + classes.mrAuto + " " + classes.textCenter
              }
            >
              <h2 className={classes.title}>Services We Offer</h2>
              <br />
            </GridItem>
          </GridContainer>
          <Card plain blog className={classes.card}>
            <GridContainer>
              <GridItem xs={12} sm={5} md={5}>
                <CardHeader image plain>
                  <a onClick={e => e.preventDefault()}>
                    <img
                      src="https://storage.googleapis.com/mowforyourmoney-images/houselanding.JPG"
                      alt="..."
                    />
                  </a>
                  <div
                    className={classes.coloredShadow}
                    style={{
                      backgroundImage:
                        "https://storage.googleapis.com/mowforyourmoney-images/houselanding.JPG",
                      opacity: "1"
                    }}
                  />
                </CardHeader>
              </GridItem>
              <GridItem xs={12} sm={7} md={7}>
                <h3 className={classes.cardTitle}>
                  <a onClick={e => e.preventDefault()}>Lawn Care</a>
                </h3>
                <p className={classes.description1}>
                  Weekly Lawn mowing helps maintain the appearance of your lawn.
                  We provide your lawn with the best cuts every week by always
                  having sharp blades, and trimming around anything the mower
                  can not get, and after blowing all grass clippings back into
                  the lawn. Leaving your lawn with the appearance of a baseball
                  park.
                </p>
              </GridItem>
            </GridContainer>
          </Card>

          <Card plain blog className={classes.card}>
            <GridContainer>
              <GridItem xs={12} sm={5} md={5}>
                <CardHeader image plain>
                  <a onClick={e => e.preventDefault()}>
                    <img
                      src={
                        "https://storage.googleapis.com/mowforyourmoney-images/SpringCleanup.jpg"
                      }
                      alt="..."
                    />
                  </a>
                  <div
                    className={classes.coloredShadow}
                    style={{
                      backgroundImage:
                        "https://storage.googleapis.com/mowforyourmoney-images/SpringCleanup.jpg",
                      opacity: "1"
                    }}
                  />
                </CardHeader>
              </GridItem>
              <GridItem xs={12} sm={7} md={7}>
                <h3 className={classes.cardTitle}>
                  <a onClick={e => e.preventDefault()}>
                    Spring Clean up/Dethatching
                  </a>
                </h3>
                <p className={classes.description1}>
                  Spring Cleanup is an important service to start your lawn off
                  on the right foot. We provide the lawn with a once over to
                  pick up any left over leaves from the fall and clean out the
                  garden beds. We go over the lawn and Dethatch and pick up all
                  of the thatch. Dethatching is important because it removes the
                  thatch that has built up over the past year. If there is
                  excess amount of thatch on top of your soil it prevents the
                  water from penetrating the soil, and acts as a sponge.
                </p>
              </GridItem>
            </GridContainer>
          </Card>
          <Card plain blog className={classes.card}>
            <GridContainer>
              <GridItem xs={12} sm={5} md={5}>
                <CardHeader image plain>
                  <a onClick={e => e.preventDefault()}>
                    <img
                      src="https://storage.googleapis.com/mowforyourmoney-images/IMG_1728.jpeg"
                      alt="..."
                      style={{ transform: [{ rotate: "14deg" }] }}
                    />
                  </a>
                  <div
                    className={classes.coloredShadow}
                    style={{
                      backgroundImage:
                        "https://storage.googleapis.com/mowforyourmoney-images/IMG_1728.jpeg",
                      opacity: "1",
                      transform: [{ rotate: "14deg" }]
                    }}
                  />
                </CardHeader>
              </GridItem>
              <GridItem xs={12} sm={7} md={7}>
                <h3 className={classes.cardTitle}>
                  <a onClick={e => e.preventDefault()}>Fall Clean up</a>
                </h3>
                <p className={classes.description1}>
                  Fall Cleanup service is provided in the Fall when most of the
                  leaves have finished dropping. We blow leaves out of all
                  garden beds, off of patios and decks, and take away all of the
                  leaves. This leaves your lawn clean of debris for the upcoming
                  winter, while still leaving some mulched up leaves for
                  fertilizer.
                </p>
              </GridItem>
            </GridContainer>
          </Card>

          <Card plain blog className={classes.card}>
            <GridContainer>
              <GridItem xs={12} sm={5} md={5}>
                <CardHeader image plain>
                  <a onClick={e => e.preventDefault()}>
                    <img
                      src="https://storage.googleapis.com/mowforyourmoney-images/36723873_977173885790375_4784850737500782592_n.jpg"
                      alt="..."
                    />
                  </a>
                  <div
                    className={classes.coloredShadow}
                    style={{
                      backgroundImage:
                        "https://storage.googleapis.com/mowforyourmoney-images/36723873_977173885790375_4784850737500782592_n.jpg",
                      opacity: "1"
                    }}
                  />
                </CardHeader>
              </GridItem>
              <GridItem xs={12} sm={7} md={7}>
                <h3 className={classes.cardTitle}>
                  <a onClick={e => e.preventDefault()}>Aeration</a>
                </h3>
                <p className={classes.description1}>
                  Aeration is offered in the Fall, aeration is often overlooked
                  by most homeowners. Aeration is a very important part of
                  proper Lawn Maintenance. Aeration takes plugs out of your lawn
                  of 2-3'' to allow your soil to breath, which helps the roots
                  grow deeper and stronger. Aeration also relieves compaction
                  from the lawn. Right after Aeration is also a perfect time to
                  do your fall fertilizer.
                </p>
              </GridItem>
            </GridContainer>
          </Card>
          <Card plain blog className={classes.card}>
            <GridContainer>
              <GridItem xs={12} sm={5} md={5}>
                <CardHeader image plain>
                  <a onClick={e => e.preventDefault()}>
                    <img
                      src="https://storage.googleapis.com/mowforyourmoney-images/FullSizeRender.jpeg"
                      alt="..."
                    />
                  </a>
                  <div
                    className={classes.coloredShadow}
                    style={{
                      backgroundImage:
                        "https://storage.googleapis.com/mowforyourmoney-images/FullSizeRender.jpeg",
                      opacity: "1"
                    }}
                  />
                </CardHeader>
              </GridItem>
              <GridItem xs={12} sm={7} md={7}>
                <h3 className={classes.cardTitle}>
                  <a onClick={e => e.preventDefault()}>
                    Snow Removal/Ice Control
                  </a>
                </h3>
                <p className={classes.description1}>
                  Our snow removal services help you come home to a clear
                  driveway every time it snows over your trigger rate. You pick
                  your trigger rate of 1", 2" or 3" and we will come clear your
                  driveway and walk ways whenever it accumulates over your
                  trigger rate. We also offer salting services at an additional
                  cost.
                </p>
              </GridItem>
            </GridContainer>
          </Card>
        </div>
      </div>
    </div>
  );
}
