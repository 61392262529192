import React from "react";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui icons
import AcUnitIcon from '@material-ui/icons/AcUnit';
import LocalFloristIcon from '@material-ui/icons/LocalFlorist';
import NatureIcon from '@material-ui/icons/Nature';
import LandscapeIcon from '@material-ui/icons/Landscape';



import featuresStyle from "assets/jss/material-kit-pro-react/views/pricingSections/featuresStyle.js";

const useStyles = makeStyles(featuresStyle);

export default function SectionFeatures() {
  const classes = useStyles();
  return (
    <div className={classes.featuresSection}>
      <div className={classes.textCenter}>
        <h3 className={classes.title}>Other Services Offered</h3>
      </div>
      <GridContainer>
        <GridItem md={4} sm={4} className={classes.mlAuto}>
          <InfoArea
            title="Dethatching"
            description="Dethatching is important because it removes the thatch that has built up over the past year. If there is excess amount of thatch on top of your soil it prevents the water from penetrating the soil, and acts as a sponge."
            icon={NatureIcon}
            iconColor="success"
          />
        </GridItem>
        <GridItem md={4} sm={4} className={classes.mrAuto}>
          <InfoArea
            title="Aeration"
            description="Aeration is offered in the Fall, aeration is often overlooked by most homeowners. Aeration is a very important part of proper Lawn Maintenance. Aeration takes plugs out of your lawn of 2-3'' to allow your soil to breath, which helps the roots grow deeper and stronger. Aeration also relieves compaction from the lawn. Right after Aeration is also a perfect time to do your fall fertilizer.
            Aeration"
            icon={LandscapeIcon}
            iconColor="success"
          />
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem md={4} sm={4} className={classes.mlAuto}>
          <InfoArea
            title="Spring/Fall Clean up"
            description="Fall Cleanup service is provided in the Fall when most of the leaves have finished dropping. We blow leaves out of all garden beds, off of patios and decks, and take away all of the leaves. This leaves your lawn clean of debris for the upcoming winter, while still leaving some mulched up leaves for fertilizer."
            icon={LocalFloristIcon}
            iconColor="success"
          />
        </GridItem>
        <GridItem md={4} sm={4} className={classes.mrAuto}>
          <InfoArea
            title="Snow Removal/Ice Control"
            description="Our snow removal services help you come home to a clear driveway every time it snows over your trigger rate. You pick your trigger rate of 1, 2 or 3 inches and we will come clear your driveway and walk ways whenever it accumulates over your trigger rate. We also offer salting services at an additional cost."
            icon={AcUnitIcon}
            iconColor="success"
          />
        </GridItem>
      </GridContainer>
    </div>
  );
}
