/*eslint-disable*/ import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
// @material-ui/icons
import Favorite from "@material-ui/icons/Favorite";
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";

import landingPageStyle from "assets/jss/material-kit-pro-react/views/landingPageStyle.js";

// Sections for this page
import SectionProduct from "./Sections/SectionProduct.js";
import SectionBlogs from "./Sections/SectionBlogs";
import SectionTestimonials from "./Sections/SectionTestimonials";

import SectionContact from "../AboutUsPage/Sections/SectionContact";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const iframe =
  '<iframe id="saFormsIframe" src="https://my.serviceautopilot.com/v3/ViewForm?id=f2f0172d-62e4-4306-a520-8ff231bfe984&websiteHost=1" width="100%" height="1000" scrolling="auto" frameborder="0" style="overflow-y:visible;"></iframe>';

function Iframe(props) {
  return (
    <div
      dangerouslySetInnerHTML={{ __html: props.iframe ? props.iframe : "" }}
    />
  );
}

const useStyles = makeStyles(landingPageStyle);
var isMobile = navigator.userAgent.match(
  /(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i
);

export default function LandingPage({ ...rest }) {
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });
  const classes = useStyles();
  return (
    <div>
      <Header
        color="transparent"
        brand="Mow For Your Money LawnCare"
        links={
          <HeaderLinks
            dropdownHoverColor="white"
            contactUsButtonAction={handleClickOpen}
          />
        }
        fixed
        changeColorOnScroll={{
          height: 300,
          color: "white",
        }}
        {...rest}
      />
      <Parallax
        image="https://storage.googleapis.com/mowforyourmoney-images/landing.jpg"
        filter="dark"
      >
        <div className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={6} md={6}>
              <h1 className={classes.title}>Two Weeks For Free</h1>
              <h4>
                Early Bird Special, sign up by April 15th and get two weeks for
                free! Only for new customers.
              </h4>
              <br />
              <Button
                color="success"
                size="lg"
                href=""
                onClick={handleClickOpen}
              >
                Learn More
              </Button>
            </GridItem>
            <GridItem xs={12} sm={5} md={5} className={classes.mlAuto}>
              <div className={classes.iframeContainer}>
                {isMobile ? (
                  <iframe
                    height="250"
                    width="500"
                    src="https://storage.googleapis.com/mowforyourmoney-images/logo"
                    frameBorder="0"
                    allow="encrypted-media"
                    allowFullScreen=""
                    title="logo"
                  />
                ) : (
                  <iframe
                    height="500"
                    width="500"
                    src="https://storage.googleapis.com/mowforyourmoney-images/logo"
                    frameBorder="0"
                    allow="encrypted-media"
                    allowFullScreen=""
                    title="logo"
                  />
                )}
              </div>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <Dialog
            fullScreen
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
          >
            <AppBar className={classes.appBar} color="default">
              <Toolbar>
                <IconButton
                  edge="start"
                  color="default"
                  onClick={handleClose}
                  aria-label="close"
                >
                  <CloseIcon />
                </IconButton>
              </Toolbar>
            </AppBar>
            <Iframe iframe={iframe} />,
          </Dialog>
          <SectionProduct />
          <SectionBlogs />
          <SectionTestimonials />
          <SectionContact />
        </div>
      </div>
      <Footer
        content={
          <div>
            <div className={classes.left}>
              <List className={classes.list}>
                <ListItem className={classes.inlineBlock}>
                  <a href="/" className={classes.block}>
                    Home
                  </a>
                </ListItem>
                <ListItem className={classes.inlineBlock}>
                  <a href="/services" className={classes.block}>
                    Services
                  </a>
                </ListItem>
                <ListItem className={classes.inlineBlock}>
                  <a href="/about-us" className={classes.block}>
                    About us
                  </a>
                </ListItem>
                <ListItem className={classes.inlineBlock}>
                  <a onClick={handleClickOpen} className={classes.block}>
                    Contact us
                  </a>
                </ListItem>
              </List>
            </div>
            <div className={classes.right}>
              &copy; {1900 + new Date().getYear()} , Mow For Your Money Lawncare
              All Rights Reserved.
            </div>
          </div>
        }
      />
    </div>
  );
}
