import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";


import testimonialsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/testimonialsStyle.js";



const useStyles = makeStyles(testimonialsStyle);

export default function SectionTestimonials({ ...rest }) {
  const classes = useStyles();
 
  return (
    <div className="cd-section" {...rest}>
      <div className={classes.testimonials}>
        <div className={classes.container}>
          <GridContainer>
            <GridItem
              xs={12}
              sm={6}
              md={6}
              className={
                classes.mlAuto + " " + classes.mrAuto + " " + classes.textCenter
              }
            >
              <h2 className={classes.title}>What Our Clients Say</h2>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={4} md={4}>
              <Card testimonial plain>
                <CardBody plain>
                  <h4 className={classes.title}>Logan Bratton</h4>
                  <h5 className={classes.cardDescription}>
                    {'"'}I used them to take care of my lawn. They are in and
                    out with no issues. I would recommend!! Great price!!{'"'}
                  </h5>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <Card testimonial plain>
                <CardBody plain>
                  <h4 className={classes.title}>Preenon Huq</h4>

                  <h5 className={classes.cardDescription}>
                    {'"'}Staff is very friendly, work is completed on time and
                    in the highest quality at an affordable price. Highly
                    recommend them.{'"'}
                  </h5>
                </CardBody>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={4} md={4}>
              <Card testimonial plain>
                <CardBody plain>
                  <h4 className={classes.title}>Jeff Meland</h4>

                  <h5 className={classes.cardDescription}>
                    {'"'}Very high quality work at a price I am happy to pay.
                    Staff is friendly and easy to work with. I definitely get
                    "mow" for my money!{'"'}
                  </h5>
                </CardBody>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
}
