import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

import ImportantDevicesIcon from '@material-ui/icons/ImportantDevices';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import VpnLockIcon from '@material-ui/icons/VpnLock';
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";


import productStyle from "assets/jss/material-kit-pro-react/views/landingPageSections/productStyle.js";

const useStyles = makeStyles(productStyle);

export default function SectionProduct() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={8} md={8}>
          <h2 className={classes.title}>Why Our Clients Love Us: </h2>
          <h5 className={classes.description}>
          Our clients come back year after year. We thrive on our customer relationships with each and every client. Proper communication and great quality service play a huge role in our success. 
          </h5>
        </GridItem>
      </GridContainer>
      <div>
        <GridContainer>
          <GridItem xs={12} sm={4} md={4}>
            <InfoArea
              title="Free Estimates"
              description="We offer free estimates too all of our clients whether they are residential or commercial. We provide honest upfront pricing on all of our services."
              icon={AttachMoneyIcon}
              iconColor="success"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={4} md={4}>
            <InfoArea
              title="Client Portal"
              description="Mow For Your Money Lawn Care’s Client Portal allows our clients to access their bills online. You can pay your invoice, request services, and ask questions or voice any concerns through the Client Portal. Clients can add their credit card for automatic payments as well."
              icon={VpnLockIcon}
              iconColor="success"
              vertical
            />
          </GridItem>
          <GridItem xs={12} sm={4} md={4}>
            <InfoArea
              title="Paperless Invoices"
              description="Mow For Your Money Lawn Care provides a paperless invoice option. We like to email invoices to our clients which allows them to pay online. Or you can view your current or past invoices through our Client Portal."
              icon={ImportantDevicesIcon}
              iconColor="success"
              vertical
            />
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
